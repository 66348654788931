<template>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-main row wrap>
      <v-layout row wrap align-center>
        <v-flex xs12>
          <br><br>
          <span class="main-title"><b>ЦИФРОВАЯ ЭКОСИСТЕМА</b></span><br>
          <span class="main-title"><b>Школа   Ученик   Родитель</b></span><br><br><br><br><br><br>
          <h2 style="color: #FFFF">
            Реалии современного мира таковы, что все мы уже сейчас живем в цифровом мобильном мире.<br>
            Наши дети с малых лет умеют пользоваться смартфоном и не могут без него.<br>
            Мы сами то же привыкли быть на связи и в курсе происходящего в режиме online.<br><br>

            ПРИШЛА ПОРА ОБЪЕДИНИТЬ ШКОЛУ, ДЕТЕЙ И РОДИТЕЛЕЙ В ЕДИНОМ ЦИФРОВОМ МИРЕ
          </h2><br><br>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <br>
            <v-card-text align="left">
              <h1 align="center">Ученик</h1>
              <h3 align="center">Как заинтересовать?</h3>
              <h3 align="center">Ответ: Мобильное приложение.</h3>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/mobile_app.png" width="50%"></v-img>
              </v-col>
              <v-col align="left">
                <p>Дети пользуются смартфоном постоянно.</p><br>
                <p>
                  Посмотреть домашнее задание и оценки, расписание уроков,
                  сообщения от учителя детям в смартфоне удобнее, чем в дневнике.
                </p><br>
                <p>
                  Дети смогут пообщаться между собой в чате класса без взрослой
                  рекламы. И будет отдельный, официальный чат с классным руководителем.
                </p><br>
                <p>
                  Смогут привыкнуть к взрослым технологиям - посмотреть свои
                  расходы по школьной карте, оплатить покупки в проверенных местах.
                </p><br>
                <p>
                  Вместо взрослой рекламы - афиша городских мероприятий для детей,
                  информация о событиях в школе.
                </p><br>
                <p>Смартфон сможет напоминать ребенку о домашнем задании, о том, что пора спать.</p><br><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Школа</h1>
              <h3 align="center">А что для учителя?</h3>
              <h3 align="center">Ответ: Функциональный личный кабинет в компьютере.</h3>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/school.jpg" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Для классного руководителя отдельные чаты:<br>
                  - общий с учениками класса;<br>
                  - индивидуальный с каждым учеником;<br>
                  - общий с родителями учеников класса;<br>
                  - индивидуальный с каждым родителем.<br>
                </p><br>
                <p>
                  Современным детям (и многим родителям) проще написать, чем сказать!
                </p><br>
                <p>
                  Классный руководитель сможет организовывать посещение мероприятий и избавиться от сбора денег.
                </p><br>
                <p>
                  Возможность проведения голосований для учеников и родителей, сбора оценки проведенных мероприятий (лайк \ дизлайк).
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Родители</h1>
              <h3 align="center">Как быть в курсе и контролировать? </h3>
              <h3 align="center">Ответ: Информационный чат-бот.</h3>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/parents.png" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Родителей сложнее,чем детей заставить установить мобильное приложение,
                  поэтому мы решили использовать чат-бот для мессенджера на выбор:
                  Telegram, Viber, ВКонтакте, Facebook, Яндекс Мессенджер.
                </p><br>
                <p>
                  Родили смогут получать сообщения о событиях:<br>
                  - проходы в образовательные организации;<br>
                  - проезд в общественном транспорте;<br>
                  - школьное питание с расшифровкой по блюдам и с указанием их калорийности;<br>
                  - о покупках сделанных ребенком по школьной карте;<br>
                  - оценки и домашние задания;<br>
                  - сообщения от учителя;<br>
                  - информация о низком балансе на школьной карте;<br>
                  - получить геолокацию ребенка.<br>
                </p><br>
                <p>
                  Чат-бот будет интерактивным. Ему можно задавать вопросы и он будет отвечать.
                  Например, узнать баланс школьной карты или запросить геолокацию ребенка
                  (об этом сообщит мобильное приложение на смартфоне ребенка).
                  Через чат-бот, используя Систему быстрых платежей (СБП), можно будет
                  мгновенно пополнить баланс школьной карты.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Антипотеряшка</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/antiloss.png" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Дети постоянно что-то забывают и теряют. И это нормально.
                  В школах под забыте вещи учеников делают целые комнаты или вешают на стену.
                  Тут и ключи, и ранец, и пакет со сменной обувью.
                </p><br>
                <p>
                  Помочь в решении проблемы может брелок АНТИпотеряшка.
                </p><br>
                <p>
                  На АНТИпотеряшке нанесен уникальный номер и QR-код,
                  которые связаны с информацией в интернет.
                </p><br>
                <p>
                  АНТИпотеряшка крепится, например, к пакету со сменной обувью.
                  Если пакет потерялся, то в городе скорее всего его кто-то найдет,
                  но не будет знать кому вернуть.
                </p><br>
                <p>
                  Нашедший отсканирует QR-код и ему откроется информация с контактами,
                  которую оставил владелец АНТИпотеряшки.
                </p><br>
                <p>Потеряшка найдет своего хозяина!</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Шкафчики</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/lockers.png" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Индивидуальные школьные шкафчики предназначены для хранения одежды и личных вещей учеников.
                </p><br>
                <p>
                  Родители, пожелавшие забронировать шкафчик, могут выбрать ячейку
                  и оформить подписку в личном кабинете школьной карты.
                </p><br>
                <p>
                  Использование индивидуальных шкафчиков удобно для учеников:
                  одежду никто не уронит и не испачкает, можно хранить и не носить
                  домой сменную обувь и школьные принадлежности, на время уроков оставлять ненужные вещи.
                </p><br>
                <p>
                  Проведенный осенью 2020 года опрос родителей в социальной сети
                  ВКонтакте показал, что большинство родителей заинтересованы в
                  использовании индивидуальных шкафчиков.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Школьная карта</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/schoolcard.jpg" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Школьная карта получит еще больше возможностей.
                  Сейчас по ней можно проходить в образовательные организации,
                  оплачивать питание в школьных столовых и ездить на общественном транспорте.
                </p><br>
                <p>
                  Дополнительно по школьной карте можно будет оплачивать покупки
                  в проверенных местах. Например: в кафе, в спортивной секции, в музее, в кинотеатре.
                </p><br>
                <p>
                  Обналичить деньги с карты по прежнему будет не возможно.
                </p><br>
                <p>
                  Родители будут незамедлительно получать сообщения обо всех операциях в чат-бот.
                </p><br>
                <p>
                  Ребенок сможет отслеживать свои расходы и баланс в своем школьном мобильном приложении.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Банковская карта Мир</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/bankcard.jpg" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  В системе смогут использоваться карты МИР для прохода
                  в учебное заведение и оплаты школьного питания.
                </p><br>
                <p>
                  Банковскую карту можно использовать по прямому функционалу без ограничений,
                  но ее использование будет под ответственность родителей.
                  Выдача карт разрешена только при достижении 14 лет.
                </p><br>
                <p>
                  В отличии от школьной карты, используя карту МИР родители не
                  смогут получать уведомления в полном объеме.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Цифровой профиль школьника</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/digital_profile.png" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Цифровой профиль школьника является совокупностью данных,
                  которые собираются и накапливаются в электронных базах различных структур.
                </p><br>
                <p>
                  Министр просвещения Сергей Кравцов сообщил, что все успехи и неудачи
                  ученика цифровая образовательная среда зафиксирует в цифровой биографии — она покажет,
                  в чем школьник успевает, а где отстает.
                </p><br>
                <p>
                  Сейчас планируется начало эксперимента по внедрению цифровой
                  образовательной среды, где цифровой профиль школьника станет частью этой среды.
                </p><br>
                <p>
                  Цифровой профиль школьника  сделает видимыми и измеримыми все успехи
                  и неудачи школьника: прогресс будет фиксироваться в системе и объективно показывать,
                  в чем школьник успевает, чем интересуется, что кушает, что посещает и в чем ему нужна помощь.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-card-text  align="left">
              <h1 align="center">Что нужно?</h1>
            </v-card-text>
            <br><br>
            <v-row style="margin-bottom: 50px;">
              <v-col align="center">
                <v-img src="img/question.jpg" width="400px"></v-img>
              </v-col>
              <v-col align="left">
                <p>
                  Для полноценной реализации проекта необходимо оперативно получать
                  информацию из систем электронного дневника (расписание, оценки, домашние задания).
                </p><br>
                <p>
                  На даный момент мы не имеем доступа к этой информации, хотя,
                  являясь оператором школьной карты мы имеем большую часть информации
                  и у нас решен вопрос с хранением персональных данных согласно законодательства.
                </p><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-bottom:50px; padding-top:50px;">
              <v-col align="center">
                <v-img src="img/cds_logo.png" max-width="300px"></v-img>
              </v-col>
              <v-col>
                <strong>МБУ "ЦДС ГПТ"</strong><br><br>
                <strong>8 (8332) 48-59-94</strong><br><br>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-flex>
      </v-layout>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'Main',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.main-title {
  margin-top: 60px;
  font-size: 3.5em;
  color: #FFFF;
}
p {
  padding: 15px;
  font-size: 20px;
}
h1 {
  font-size:40px;
  line-height:45px;
}
h3 {
  font-size: 30px;
  line-height:35px;
}
h2 {
  font-size: 35px;
  line-height:40px;
}
</style>
